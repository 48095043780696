import "../styles/globals.css";

import "../styles/loaders.css";
import "../styles/Calendar.css";
import "../styles/DatePicker.css";

import { SessionProvider } from "next-auth/react";
import GooglePlacesScript from "../components/GooglePlacesScript";
import Head from "next/head";
import Providers from "@/components/providers/Providers";
import { initializeApp } from "firebase/app";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import { CookiesProvider } from "react-cookie";
import { userService } from "/services";
import { AppProps } from "next/app";
import { urls } from "/helpers";
import App from "next/app";

let firebaseConfig;

firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FB_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FB_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FB_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FB_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FB_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FB_MEASUREMENT_ID,
};

const firebaseApp = initializeApp(firebaseConfig);

const theme = createTheme({
  typography: {
    fontFamily: ["Poppins"].join(","),
  },
  palette: {
    primary: {
      main: "#d71f69", // "#be8688", // "#d71f69",
    },
    secondary: {
      main: "#ffc5a8",
    },
  },
  components: {
    // Name of the component
    MuiButton: {
      defaultProps: {
        sx: {
          textTransform: "none",
          fontWeight: "500",
          whiteSpace: "nowrap",
          // p: 4,
          px: 3,
          height: "45px",
        },
        size: "small",
        variant: "contained",
        disableElevation: true,
        // fullWidth: true,
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        variant: "contained",
      },
    },
  },
});

function MyApp({ Component, pageProps, appData }) {
  return (
    <div className="flexx h-full w-full">
      <ThemeProvider theme={theme}>
        <CookiesProvider>
          <SessionProvider session={pageProps.session}>
            <Providers appData={appData}>
              <Head>
                <title>EventFour</title>
                <meta
                  name="viewport"
                  content="width=device-width,initial-scale=1.0, minimum-scale=1.0, user-scalable=no"
                />
              </Head>
              <GooglePlacesScript />
              <Component {...pageProps} />
            </Providers>
          </SessionProvider>
        </CookiesProvider>
      </ThemeProvider>
    </div>
  );
}

MyApp.getInitialProps = async (ctx) => {
  const appProps = await App.getInitialProps(ctx);

  const res = await fetch(urls.data);
  const json = await res.json();
  // console.log(json);
  return {
    ...appProps,
    appData: { eventTypes: json.eventTypes, serviceTypes: json.serviceTypes },
  };
};

export default MyApp;

// export const getServerSideProps = async ({ req, res }) => {
//   const resx = await fetch(urls.data);

//   if (!resx.ok) {
//     const error = await resx.json();
//     throw error.error;
//   }

//   const appData = await resx.json();
//   return {
//     props: {
//       appData,
//       firebaseConfig: {
//         apiKey: "AIzaSyCQDP7RmKv7QVN926tXevm3J2LPCkxBAh0",
//         authDomain: "eventfour-1ea7e.firebaseapp.com",
//         projectId: "eventfour-1ea7e",
//         storageBucket: "eventfour-1ea7e.appspot.com",
//         messagingSenderId: "6881914388",
//         appId: "1:6881914388:web:50a1cd43d1d8adfc9fc101",
//         measurementId: "G-3HCEFX3NQG",
//       },
//     },
//   };
// };
