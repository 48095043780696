import {
  getCookie,
  getCookies,
  setCookie,
  hasCookie,
  deleteCookie,
} from "cookies-next";
import { fetchWrapper, urls } from "/helpers";
import { useSession } from "next-auth/react";
import { _post, _get } from "./index";
import { swrResponse, userService } from "/services";
import { setToStorage, getFromStorage, constants } from "/helpers";
import useSWR from "swr";

async function search(query) {
  const endpoint = urls.search + `?query=${query}`;
  const payload = {};
  //call api
  return _post(endpoint, payload);
}

function getPriceUnits() {
  return [
    { id: 1, name: "per cake" },
    { id: 1, name: "per copy" },
    { id: 1, name: "per cup" },
    { id: 1, name: "per day" },
    { id: 1, name: "per drink" },
    { id: 1, name: "per event" },
    { id: 1, name: "per guest" },
    { id: 1, name: "per head" },
    { id: 1, name: "per hour" },
    { id: 1, name: "per kg" },
    { id: 1, name: "per look" },
    { id: 1, name: "per minute" },
    { id: 1, name: "per outfit" },
    { id: 1, name: "per page" },
    { id: 1, name: "per photo" },
    { id: 1, name: "per plate" },
    { id: 1, name: "per session" },
    { id: 1, name: "per video" },
  ];
}

function getServiceCategories() {
  // return [
  //   {
  //     id: 1,
  //     name: "Bridal Wardrobe",
  //     icon: "0xe803",
  //     vendors: 0,
  //     image: {
  //       thumbnail: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       small: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       medium: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       large: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //     },
  //     filters: [],
  //   },
  //   {
  //     id: 2,
  //     name: "Cake",
  //     icon: "0xe823",
  //     vendors: 5,
  //     image: {
  //       thumbnail: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       small: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       medium: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       large: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //     },
  //     filters: [],
  //   },
  //   {
  //     id: 3,
  //     name: "Catering",
  //     icon: "0xe809",
  //     vendors: 2,
  //     image: {
  //       thumbnail: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       small: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       medium: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       large: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //     },
  //     filters: [],
  //   },
  //   {
  //     id: 4,
  //     name: "DJ",
  //     icon: "0xe822",
  //     vendors: 0,
  //     image: {
  //       thumbnail: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       small: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       medium: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       large: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //     },
  //     filters: [],
  //   },
  //   {
  //     id: 5,
  //     name: "Decorator",
  //     icon: "0xe805",
  //     vendors: 0,
  //     image: {
  //       thumbnail: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       small: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       medium: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       large: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //     },
  //     filters: [],
  //   },
  //   {
  //     id: 6,
  //     name: "Drinks",
  //     icon: "0xe80b",
  //     vendors: 0,
  //     image: {
  //       thumbnail: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       small: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       medium: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       large: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //     },
  //     filters: [],
  //   },
  //   {
  //     id: 16,
  //     name: "Event Planner",
  //     icon: "0xe816",
  //     vendors: 3,
  //     image: {
  //       thumbnail: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       small: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       medium: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       large: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //     },
  //     filters: [],
  //   },
  //   {
  //     id: 7,
  //     name: "Florist / Bouquets",
  //     icon: "0xe80d",
  //     vendors: 1,
  //     image: {
  //       thumbnail: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       small: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       medium: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       large: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //     },
  //     filters: [],
  //   },
  //   {
  //     id: 9,
  //     name: "Graphics / Stationery",
  //     icon: "0xe811",
  //     vendors: 0,
  //     image: {
  //       thumbnail: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       small: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       medium: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       large: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //     },
  //     filters: [],
  //   },
  //   {
  //     id: 8,
  //     name: "Groom Wardrobe",
  //     icon: "0xe802",
  //     vendors: 0,
  //     image: {
  //       thumbnail: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       small: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       medium: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       large: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //     },
  //     filters: [],
  //   },
  //   {
  //     id: 10,
  //     name: "Hair Stylist",
  //     icon: "0xe826",
  //     vendors: 0,
  //     image: {
  //       thumbnail: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       small: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       medium: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       large: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //     },
  //     filters: [],
  //   },
  //   {
  //     id: 11,
  //     name: "MC / Comedian",
  //     icon: "0xe815",
  //     vendors: 0,
  //     image: {
  //       thumbnail: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       small: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       medium: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       large: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //     },
  //     filters: [],
  //   },
  //   {
  //     id: 12,
  //     name: "Makeup Artist",
  //     icon: "0xe812",
  //     vendors: 0,
  //     image: {
  //       thumbnail: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       small: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       medium: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       large: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //     },
  //     filters: [],
  //   },
  //   {
  //     id: 13,
  //     name: "Medic",
  //     icon: "0xe80f",
  //     vendors: 0,
  //     image: {
  //       thumbnail: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       small: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       medium: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       large: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //     },
  //     filters: [],
  //   },
  //   {
  //     id: 14,
  //     name: "Musician / Live Band",
  //     icon: "0xe814",
  //     vendors: 0,
  //     image: {
  //       thumbnail: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       small: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       medium: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       large: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //     },
  //     filters: [],
  //   },
  //   {
  //     id: 15,
  //     name: "Photographer",
  //     icon: "0xe81f",
  //     vendors: 0,
  //     image: {
  //       thumbnail: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       small: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       medium: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       large: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //     },
  //     filters: [],
  //   },
  //   {
  //     id: 17,
  //     name: "Rentals",
  //     icon: "0xe81a",
  //     vendors: 0,
  //     image: {
  //       thumbnail: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       small: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       medium: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       large: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //     },
  //     filters: [],
  //   },
  //   {
  //     id: 18,
  //     name: "Security",
  //     icon: "0xe810",
  //     vendors: 0,
  //     image: {
  //       thumbnail: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       small: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       medium: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       large: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //     },
  //     filters: [],
  //   },
  //   {
  //     id: 19,
  //     name: "Sound",
  //     icon: "0xe804",
  //     vendors: 0,
  //     image: {
  //       thumbnail: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       small: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       medium: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       large: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //     },
  //     filters: [],
  //   },
  //   {
  //     id: 20,
  //     name: "Souvenir",
  //     icon: "0xe817",
  //     vendors: 0,
  //     image: {
  //       thumbnail: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       small: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       medium: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       large: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //     },
  //     filters: [],
  //   },
  //   {
  //     id: 21,
  //     name: "Stage",
  //     icon: "0xe824",
  //     vendors: 0,
  //     image: {
  //       thumbnail: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       small: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       medium: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       large: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //     },
  //     filters: [],
  //   },
  //   {
  //     id: 22,
  //     name: "Transportation / Logistics",
  //     icon: "0xe807",
  //     vendors: 0,
  //     image: {
  //       thumbnail: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       small: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       medium: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       large: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //     },
  //     filters: [],
  //   },
  //   {
  //     id: 23,
  //     name: "Ushers / Waiters",
  //     icon: "0xe818",
  //     vendors: 0,
  //     image: {
  //       thumbnail: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       small: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       medium: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       large: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //     },
  //     filters: [],
  //   },
  //   {
  //     id: 24,
  //     name: "Venue",
  //     icon: "0xe80a",
  //     vendors: 0,
  //     image: {
  //       thumbnail: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       small: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       medium: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       large: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //     },
  //     filters: [],
  //   },
  //   {
  //     id: 25,
  //     name: "Videographer",
  //     icon: "0xe825",
  //     vendors: 0,
  //     image: {
  //       thumbnail: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       small: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       medium: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //       large: "https://eventfour.s3.amazonaws.com/avatars/no_avatar.png",
  //     },
  //     filters: [],
  //   },
  // ];
}

const useGetEventTypes = async () => {
  const url = urls.data;
  const fetcher = await fetchWrapper.get(url, { swr: false, authorize: false });
  console.log("ppppppppppp");
  if (fetcher) {
    localStorage.setItem(
      constants.DATA_EVENT_TYPES,
      JSON.stringify(fetcher.eventTypes)
    );
    localStorage.setItem(
      constants.DATA_SERVICE_TYPES,
      JSON.stringify(fetcher.serviceTypes)
    );

    return fetcher;
  }

  return { eventTypes: [], serviceTypes: [] };
};

async function getEventTypes() {
  //check if its in store.
  // const url = urls.data;
  // const data = await fetchWrapper.get(url, { swr: false, authorize: false });
  // console.log(data);
  // return [
  //   {
  //     id: 7,
  //     name: "Wedding",
  //     icon: "0xe806",
  //     photo:
  //       "https://images.unsplash.com/photo-1606800052052-a08af7148866?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
  //   },
  //   {
  //     id: 2,
  //     name: "Birthday",
  //     icon: "0xe823",
  //     photo:
  //       "https://images.unsplash.com/photo-1583875762487-5f8f7c718d14?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80",
  //   },
  //   {
  //     id: 4,
  //     name: "Corporate",
  //     icon: "0xe821",
  //     photo:
  //       "https://images.unsplash.com/photo-1507878866276-a947ef722fee?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80",
  //   },
  //   {
  //     id: 1,
  //     name: "Anniversary",
  //     icon: "0xe805",
  //     photo:
  //       "https://images.unsplash.com/photo-1573157941034-ba27a5097323?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80",
  //   },
  //   {
  //     id: 3,
  //     name: "Bridal Shower",
  //     icon: "0xe826",
  //     photo:
  //       "https://images.unsplash.com/photo-1527275393322-8ddae8bd5de9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80",
  //   },
  //   {
  //     id: 5,
  //     name: "Funeral",
  //     icon: "0xe806",
  //     photo:
  //       "https://images.unsplash.com/photo-1515934751635-c81c6bc9a2d8?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8d2VkZGluZ3xlbnwwfDB8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60",
  //   },
  //   {
  //     id: 6,
  //     name: "Concert",
  //     icon: "0xe806",
  //     photo:
  //       "https://images.unsplash.com/photo-1515934751635-c81c6bc9a2d8?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8d2VkZGluZ3xlbnwwfDB8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60",
  //   },
  //   {
  //     id: 8,
  //     name: "Other",
  //     icon: "0xe808",
  //     photo:
  //       "https://images.unsplash.com/photo-1524368535928-5b5e00ddc76b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
  //   },
  //   {
  //     id: 9,
  //     name: "Rave/Club Parties",
  //     icon: "0xe808",
  //     photo:
  //       "https://images.unsplash.com/photo-1524368535928-5b5e00ddc76b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
  //   },
  // ];
}

function getStates() {
  return [
    {
      code: "FC",
      name: "Abuja",
      lgas: ["Abuja", "Kwali", "Kuje", "Gwagwalada", "Bwari", "Abaji"],
    },
    {
      code: "AB",
      name: "Abia",
      lgas: [
        "Aba North",
        "Aba South",
        "Arochukwu",
        "Bende",
        "Ikawuno",
        "Ikwuano",
        "Isiala-Ngwa North",
        "Isiala-Ngwa South",
        "Isuikwuato",
        "Umu Nneochi",
        "Obi Ngwa",
        "Obioma Ngwa",
        "Ohafia",
        "Ohaozara",
        "Osisioma",
        "Ugwunagbo",
        "Ukwa West",
        "Ukwa East",
        "Umuahia North",
        "Umuahia South",
      ],
    },
    {
      code: "AD",
      name: "Adamawa",
      lgas: [
        "Demsa",
        "Fufore",
        "Ganye",
        "Girei",
        "Gombi",
        "Guyuk",
        "Hong",
        "Jada",
        "Lamurde",
        "Madagali",
        "Maiha",
        "Mayo-Belwa",
        "Michika",
        "Mubi-North",
        "Mubi-South",
        "Numan",
        "Shelleng",
        "Song",
        "Toungo",
        "Yola North",
        "Yola South",
      ],
    },
    {
      code: "AK",
      name: "Akwa Ibom",
      lgas: [
        "Abak",
        "Eastern-Obolo",
        "Eket",
        "Esit-Eket",
        "Essien-Udim",
        "Etim-Ekpo",
        "Etinan",
        "Ibeno",
        "Ibesikpo-Asutan",
        "Ibiono-Ibom",
        "Ika",
        "Ikono",
        "Ikot-Abasi",
        "Ikot-Ekpene",
        "Ini",
        "Itu",
        "Mbo",
        "Mkpat-Enin",
        "Nsit-Atai",
        "Nsit-Ibom",
        "Nsit-Ubium",
        "Obot-Akara",
        "Okobo",
        "Onna",
        "Oron",
        "Oruk Anam",
        "Udung-Uko",
        "Ukanafun",
        "Urue-Offong/Oruko",
        "Uruan",
        "Uyo",
      ],
    },
    {
      code: "AN",
      name: "Anambra",
      lgas: [
        "Aguata",
        "Anambra East",
        "Anambra West",
        "Anaocha",
        "Awka North",
        "Awka South",
        "Ayamelum",
        "Dunukofia",
        "Ekwusigo",
        "Idemili-North",
        "Idemili-South",
        "Ihiala",
        "Njikoka",
        "Nnewi-North",
        "Nnewi-South",
        "Ogbaru",
        "Onitsha-North",
        "Onitsha-South",
        "Orumba-North",
        "Orumba-South",
      ],
    },
    {
      code: "BA",
      name: "Bauchi",
      lgas: [
        "Alkaleri",
        "Bauchi",
        "Bogoro",
        "Damban",
        "Darazo",
        "Dass",
        "Gamawa",
        "Ganjuwa",
        "Giade",
        "Itas Gadau",
        "Jama'Are",
        "Katagum",
        "Kirfi",
        "Misau",
        "Ningi",
        "Shira",
        "Tafawa-Balewa",
        "Toro",
        "Warji",
        "Zaki",
      ],
    },
    {
      code: "BY",
      name: "Bayelsa",
      lgas: [
        "Brass",
        "Ekeremor",
        "Kolokuma Opokuma",
        "Nembe",
        "Ogbia",
        "Sagbama",
        "Southern-Ijaw",
        "Yenagoa",
      ],
    },
    {
      code: "BE",
      name: "Benue",
      lgas: [
        "Ado",
        "Agatu",
        "Apa",
        "Buruku",
        "Gboko",
        "Guma",
        "Gwer-East",
        "Gwer-West",
        "Katsina-Ala",
        "Konshisha",
        "Kwande",
        "Logo",
        "Makurdi",
        "Ogbadibo",
        "Ohimini",
        "Oju",
        "Okpokwu",
        "Otukpo",
        "Tarka",
        "Ukum",
        "Ushongo",
        "Vandeikya",
      ],
    },
    {
      code: "BO",
      name: "Borno",
      lgas: [
        "Abadam",
        "Askira-Uba",
        "Bama",
        "Bayo",
        "Biu",
        "Chibok",
        "Damboa",
        "Dikwa",
        "Gubio",
        "Guzamala",
        "Gwoza",
        "Hawul",
        "Jere",
        "Kaga",
        "Kala Balge",
        "Konduga",
        "Kukawa",
        "Kwaya-Kusar",
        "Mafa",
        "Magumeri",
        "Maiduguri",
        "Marte",
        "Mobbar",
        "Monguno",
        "Ngala",
        "Nganzai",
        "Shani",
      ],
    },
    {
      code: "CR",
      name: "CrossRiver",
      lgas: [
        "Abi",
        "Akamkpa",
        "Akpabuyo",
        "Bakassi",
        "Bekwarra",
        "Biase",
        "Boki",
        "Calabar-Municipal",
        "Calabar-South",
        "Etung",
        "Ikom",
        "Obanliku",
        "Obubra",
        "Obudu",
        "Odukpani",
        "Ogoja",
        "Yakurr",
        "Yala",
      ],
    },
    {
      code: "DE",
      name: "Delta",
      lgas: [
        "Aniocha North",
        "Aniocha-North",
        "Aniocha-South",
        "Bomadi",
        "Burutu",
        "Ethiope-East",
        "Ethiope-West",
        "Ika-North-East",
        "Ika-South",
        "Isoko-North",
        "Isoko-South",
        "Ndokwa-East",
        "Ndokwa-West",
        "Okpe",
        "Oshimili-North",
        "Oshimili-South",
        "Patani",
        "Sapele",
        "Udu",
        "Ughelli-North",
        "Ughelli-South",
        "Ukwuani",
        "Uvwie",
        "Warri South-West",
        "Warri North",
        "Warri South",
      ],
    },
    {
      code: "EB",
      name: "Ebonyi",
      lgas: [
        "Abakaliki",
        "Afikpo-North",
        "Afikpo South (Edda)",
        "Ebonyi",
        "Ezza-North",
        "Ezza-South",
        "Ikwo",
        "Ishielu",
        "Ivo",
        "Izzi",
        "Ohaukwu",
        "Onicha",
      ],
    },
    {
      code: "ED",
      name: "Edo",
      lgas: [
        "Akoko Edo",
        "Egor",
        "Esan-Central",
        "Esan-North-East",
        "Esan-South-East",
        "Esan-West",
        "Etsako-Central",
        "Etsako-East",
        "Etsako-West",
        "Igueben",
        "Ikpoba-Okha",
        "Oredo",
        "Orhionmwon",
        "Ovia-North-East",
        "Ovia-South-West",
        "Owan East",
        "Owan-West",
        "Uhunmwonde",
      ],
    },
    {
      code: "EK",
      name: "Ekiti",
      lgas: [
        "Ado-Ekiti",
        "Efon",
        "Ekiti-East",
        "Ekiti-South-West",
        "Ekiti-West",
        "Emure",
        "Gbonyin",
        "Ido-Osi",
        "Ijero",
        "Ikere",
        "Ikole",
        "Ilejemeje",
        "Irepodun Ifelodun",
        "Ise-Orun",
        "Moba",
        "Oye",
      ],
    },
    {
      code: "EN",
      name: "Enugu",
      lgas: [
        "Aninri",
        "Awgu",
        "Enugu-East",
        "Enugu-North",
        "Enugu-South",
        "Ezeagu",
        "Igbo-Etiti",
        "Igbo-Eze-North",
        "Igbo-Eze-South",
        "Isi-Uzo",
        "Nkanu-East",
        "Nkanu-West",
        "Nsukka",
        "Oji-River",
        "Udenu",
        "Udi",
        "Uzo-Uwani",
      ],
    },
    {
      code: "GO",
      name: "Gombe",
      lgas: [
        "Akko",
        "Balanga",
        "Billiri",
        "Dukku",
        "Funakaye",
        "Gombe",
        "Kaltungo",
        "Kwami",
        "Nafada",
        "Shongom",
        "Yamaltu Deba",
      ],
    },
    {
      code: "IM",
      name: "Imo",
      lgas: [
        "Aboh-Mbaise",
        "Ahiazu-Mbaise",
        "Ehime-Mbano",
        "Ezinihitte",
        "Ideato-North",
        "Ideato-South",
        "Ihitte Uboma",
        "Ikeduru",
        "Isiala-Mbano",
        "Isu",
        "Mbaitoli",
        "Ngor-Okpala",
        "Njaba",
        "Nkwerre",
        "Nwangele",
        "Obowo",
        "Oguta",
        "Ohaji-Egbema",
        "Okigwe",
        "Onuimo",
        "Orlu",
        "Orsu",
        "Oru-East",
        "Oru-West",
        "Owerri-Municipal",
        "Owerri-North",
        "Owerri-West",
      ],
    },
    {
      code: "JI",
      name: "Jigawa",
      lgas: [
        "Auyo",
        "Babura",
        "Biriniwa",
        "Birnin-Kudu",
        "Buji",
        "Dutse",
        "Gagarawa",
        "Garki",
        "Gumel",
        "Guri",
        "Gwaram",
        "Gwiwa",
        "Hadejia",
        "Jahun",
        "Kafin-Hausa",
        "Kaugama",
        "Kazaure",
        "Kiri kasama",
        "Maigatari",
        "Malam Madori",
        "Miga",
        "Ringim",
        "Roni",
        "Sule-Tankarkar",
        "Taura",
        "Yankwashi",
      ],
    },
    {
      code: "KD",
      name: "Kaduna",
      lgas: [
        "Birnin-Gwari",
        "Chikun",
        "Giwa",
        "Igabi",
        "Ikara",
        "Jaba",
        "Jema'A",
        "Kachia",
        "Kaduna-North",
        "Kaduna-South",
        "Kagarko",
        "Kajuru",
        "Kaura",
        "Kauru",
        "Kubau",
        "Kudan",
        "Lere",
        "Makarfi",
        "Sabon-Gari",
        "Sanga",
        "Soba",
        "Zangon-Kataf",
        "Zaria",
      ],
    },
    {
      code: "KN",
      name: "Kano",
      lgas: [
        "Ajingi",
        "Albasu",
        "Bagwai",
        "Bebeji",
        "Bichi",
        "Bunkure",
        "Dala",
        "Dambatta",
        "Dawakin-Kudu",
        "Dawakin-Tofa",
        "Doguwa",
        "Fagge",
        "Gabasawa",
        "Garko",
        "Garun-Mallam",
        "Gaya",
        "Gezawa",
        "Gwale",
        "Gwarzo",
        "Kabo",
        "Kano-Municipal",
        "Karaye",
        "Kibiya",
        "Kiru",
        "Kumbotso",
        "Kunchi",
        "Kura",
        "Madobi",
        "Makoda",
        "Minjibir",
        "Nasarawa",
        "Rano",
        "Rimin-Gado",
        "Rogo",
        "Shanono",
        "Sumaila",
        "Takai",
        "Tarauni",
        "Tofa",
        "Tsanyawa",
        "Tudun-Wada",
        "Ungogo",
        "Warawa",
        "Wudil",
      ],
    },
    {
      code: "KT",
      name: "Katsina",
      lgas: [
        "Bakori",
        "Batagarawa",
        "Batsari",
        "Baure",
        "Bindawa",
        "Charanchi",
        "Dan-Musa",
        "Dandume",
        "Danja",
        "Daura",
        "Dutsi",
        "Dutsin-Ma",
        "Faskari",
        "Funtua",
        "Ingawa",
        "Jibia",
        "Kafur",
        "Kaita",
        "Kankara",
        "Kankia",
        "Katsina",
        "Kurfi",
        "Kusada",
        "Mai-Adua",
        "Malumfashi",
        "Mani",
        "Mashi",
        "Matazu",
        "Musawa",
        "Rimi",
        "Sabuwa",
        "Safana",
        "Sandamu",
        "Zango",
      ],
    },
    {
      code: "KE",
      name: "Kebbi",
      lgas: [
        "Aleiro",
        "Arewa-Dandi",
        "Argungu",
        "Augie",
        "Bagudo",
        "Birnin-Kebbi",
        "Bunza",
        "Dandi",
        "Fakai",
        "Gwandu",
        "Jega",
        "Kalgo",
        "Koko-Besse",
        "Maiyama",
        "Ngaski",
        "Sakaba",
        "Shanga",
        "Suru",
        "Wasagu/Danko",
        "Yauri",
        "Zuru",
      ],
    },
    {
      code: "KO",
      name: "Kogi",
      lgas: [
        "Adavi",
        "Ajaokuta",
        "Ankpa",
        "Dekina",
        "Ibaji",
        "Idah",
        "Igalamela-Odolu",
        "Ijumu",
        "Kabba Bunu",
        "Kogi",
        "Lokoja",
        "Mopa-Muro",
        "Ofu",
        "Ogori Magongo",
        "Okehi",
        "Okene",
        "Olamaboro",
        "Omala",
        "Oyi",
        "Yagba-East",
        "Yagba-West",
      ],
    },
    {
      code: "KW",
      name: "Kwara",
      lgas: [
        "Asa",
        "Baruten",
        "Edu",
        "Ekiti (Araromi/Opin)",
        "Ilorin-East",
        "Ilorin-South",
        "Ilorin-West",
        "Isin",
        "Kaiama",
        "Moro",
        "Offa",
        "Oke-Ero",
        "Oyun",
        "Pategi",
      ],
    },
    {
      code: "LA",
      name: "Lagos",
      lgas: [
        "Agege",
        "Ajeromi-Ifelodun",
        "Alimosho",
        "Amuwo-Odofin",
        "Apapa",
        "Badagry",
        "Epe",
        "Eti-Osa",
        "Ibeju-Lekki",
        "Ifako-Ijaiye",
        "Ikeja",
        "Ikorodu",
        "Kosofe",
        "Lagos-Island",
        "Lagos-Mainland",
        "Mushin",
        "Ojo",
        "Oshodi-Isolo",
        "Shomolu",
        "Surulere",
        "Yewa-South",
      ],
    },
    {
      code: "NA",
      name: "Nassarawa",
      lgas: [
        "Akwanga",
        "Awe",
        "Doma",
        "Karu",
        "Keana",
        "Keffi",
        "Kokona",
        "Lafia",
        "Nasarawa",
        "Nasarawa-Eggon",
        "Obi",
        "Wamba",
        "Toto",
      ],
    },
    {
      code: "NI",
      name: "Niger",
      lgas: [
        "Agaie",
        "Agwara",
        "Bida",
        "Borgu",
        "Bosso",
        "Chanchaga",
        "Edati",
        "Gbako",
        "Gurara",
        "Katcha",
        "Kontagora",
        "Lapai",
        "Lavun",
        "Magama",
        "Mariga",
        "Mashegu",
        "Mokwa",
        "Moya",
        "Paikoro",
        "Rafi",
        "Rijau",
        "Shiroro",
        "Suleja",
        "Tafa",
        "Wushishi",
      ],
    },
    {
      code: "OG",
      name: "Ogun",
      lgas: [
        "Abeokuta-North",
        "Abeokuta-South",
        "Ado-Odo Ota",
        "Ewekoro",
        "Ifo",
        "Ijebu-East",
        "Ijebu-North",
        "Ijebu-North-East",
        "Ijebu-Ode",
        "Ikenne",
        "Imeko-Afon",
        "Ipokia",
        "Obafemi-Owode",
        "Odeda",
        "Odogbolu",
        "Ogun-Waterside",
        "Remo-North",
        "Shagamu",
        "Yewa North",
      ],
    },
    {
      code: "ON",
      name: "Ondo",
      lgas: [
        "Akoko North-East",
        "Akoko North-West",
        "Akoko South-West",
        "Akoko South-East",
        "Akure-North",
        "Akure-South",
        "Ese-Odo",
        "Idanre",
        "Ifedore",
        "Ilaje",
        "Ile-Oluji-Okeigbo",
        "Irele",
        "Odigbo",
        "Okitipupa",
        "Ondo West",
        "Ondo-East",
        "Ose",
        "Owo",
      ],
    },
    {
      code: "OS",
      name: "Osun",
      lgas: [
        "Atakumosa West",
        "Atakumosa East",
        "Ayedaade",
        "Ayedire",
        "Boluwaduro",
        "Boripe",
        "Ede South",
        "Ede North",
        "Egbedore",
        "Ejigbo",
        "Ife North",
        "Ife South",
        "Ife-Central",
        "Ife-East",
        "Ifelodun",
        "Ila",
        "Ilesa-East",
        "Ilesa-West",
        "Irepodun",
        "Irewole",
        "Isokan",
        "Iwo",
        "Obokun",
        "Odo-Otin",
        "Ola Oluwa",
        "Olorunda",
        "Oriade",
        "Orolu",
        "Osogbo",
      ],
    },
    {
      code: "OY",
      name: "Oyo",
      lgas: [
        "Afijio",
        "Akinyele",
        "Atiba",
        "Atisbo",
        "Egbeda",
        "Ibadan North",
        "Ibadan North-East",
        "Ibadan North-West",
        "Ibadan South-East",
        "Ibadan South-West",
        "Ibarapa-Central",
        "Ibarapa-East",
        "Ibarapa-North",
        "Ido",
        "Ifedayo",
        "Irepo",
        "Iseyin",
        "Itesiwaju",
        "Iwajowa",
        "Kajola",
        "Lagelu",
        "Ogo-Oluwa",
        "Ogbomosho-North",
        "Ogbomosho-South",
        "Olorunsogo",
        "Oluyole",
        "Ona-Ara",
        "Orelope",
        "Ori-Ire",
        "Oyo-West",
        "Oyo-East",
        "Saki-East",
        "Saki-West",
        "Surulere",
      ],
    },
    {
      code: "PL",
      name: "Plateau",
      lgas: [
        "Barkin-Ladi",
        "Bassa",
        "Bokkos",
        "Jos-East",
        "Jos-North",
        "Jos-South",
        "Kanam",
        "Kanke",
        "Langtang-North",
        "Langtang-South",
        "Mangu",
        "Mikang",
        "Pankshin",
        "Qua'an Pan",
        "Riyom",
        "Shendam",
        "Wase",
      ],
    },
    {
      code: "RI",
      name: "Rivers",
      lgas: [
        "Abua Odual",
        "Ahoada-East",
        "Ahoada-West",
        "Akuku Toru",
        "Andoni",
        "Asari-Toru",
        "Bonny",
        "Degema",
        "Eleme",
        "Emuoha",
        "Etche",
        "Gokana",
        "Ikwerre",
        "Khana",
        "Obio Akpor",
        "Ogba-Egbema-Ndoni",
        "Ogba Egbema Ndoni",
        "Ogu Bolo",
        "Okrika",
        "Omuma",
        "Opobo Nkoro",
        "Oyigbo",
        "Port-Harcourt",
        "Tai",
      ],
    },
    {
      code: "SO",
      name: "Sokoto",
      lgas: [
        "Binji",
        "Bodinga",
        "Dange-Shuni",
        "Gada",
        "Goronyo",
        "Gudu",
        "Gwadabawa",
        "Illela",
        "Kebbe",
        "Kware",
        "Rabah",
        "Sabon Birni",
        "Shagari",
        "Silame",
        "Sokoto-North",
        "Sokoto-South",
        "Tambuwal",
        "Tangaza",
        "Tureta",
        "Wamako",
        "Wurno",
        "Yabo",
      ],
    },
    {
      code: "TA",
      name: "Taraba",
      lgas: [
        "Ardo-Kola",
        "Bali",
        "Donga",
        "Gashaka",
        "Gassol",
        "Ibi",
        "Jalingo",
        "Karim-Lamido",
        "Kurmi",
        "Lau",
        "Sardauna",
        "Takum",
        "Ussa",
        "Wukari",
        "Yorro",
        "Zing",
      ],
    },
    {
      code: "YO",
      name: "Yobe",
      lgas: [
        "Bade",
        "Bursari",
        "Damaturu",
        "Fika",
        "Fune",
        "Geidam",
        "Gujba",
        "Gulani",
        "Jakusko",
        "Karasuwa",
        "Machina",
        "Nangere",
        "Nguru",
        "Potiskum",
        "Tarmuwa",
        "Yunusari",
        "Yusufari",
      ],
    },
    {
      code: "ZA",
      name: "Zamfara",
      lgas: [
        "Anka",
        "Bakura",
        "Birnin Magaji/Kiyaw",
        "Bukkuyum",
        "Bungudu",
        "Gummi",
        "Gusau",
        "Isa",
        "Kaura-Namoda",
        "Kiyawa",
        "Maradun",
        "Maru",
        "Shinkafi",
        "Talata-Mafara",
        "Tsafe",
        "Zurmi",
      ],
    },
  ];
}

function getCountries() {
  return [
    { name: "Afghanistan", code: "AF" },
    { name: "land Islands", code: "AX" },
    { name: "Albania", code: "AL" },
    { name: "Algeria", code: "DZ" },
    { name: "American Samoa", code: "AS" },
    { name: "AndorrA", code: "AD" },
    { name: "Angola", code: "AO" },
    { name: "Anguilla", code: "AI" },
    { name: "Antarctica", code: "AQ" },
    { name: "Antigua and Barbuda", code: "AG" },
    { name: "Argentina", code: "AR" },
    { name: "Armenia", code: "AM" },
    { name: "Aruba", code: "AW" },
    { name: "Australia", code: "AU" },
    { name: "Austria", code: "AT" },
    { name: "Azerbaijan", code: "AZ" },
    { name: "Bahamas", code: "BS" },
    { name: "Bahrain", code: "BH" },
    { name: "Bangladesh", code: "BD" },
    { name: "Barbados", code: "BB" },
    { name: "Belarus", code: "BY" },
    { name: "Belgium", code: "BE" },
    { name: "Belize", code: "BZ" },
    { name: "Benin", code: "BJ" },
    { name: "Bermuda", code: "BM" },
    { name: "Bhutan", code: "BT" },
    { name: "Bolivia", code: "BO" },
    { name: "Bosnia and Herzegovina", code: "BA" },
    { name: "Botswana", code: "BW" },
    { name: "Bouvet Island", code: "BV" },
    { name: "Brazil", code: "BR" },
    { name: "British Indian Ocean Territory", code: "IO" },
    { name: "Brunei Darussalam", code: "BN" },
    { name: "Bulgaria", code: "BG" },
    { name: "Burkina Faso", code: "BF" },
    { name: "Burundi", code: "BI" },
    { name: "Cambodia", code: "KH" },
    { name: "Cameroon", code: "CM" },
    { name: "Canada", code: "CA" },
    { name: "Cape Verde", code: "CV" },
    { name: "Cayman Islands", code: "KY" },
    { name: "Central African Republic", code: "CF" },
    { name: "Chad", code: "TD" },
    { name: "Chile", code: "CL" },
    { name: "China", code: "CN" },
    { name: "Christmas Island", code: "CX" },
    { name: "Cocos (Keeling) Islands", code: "CC" },
    { name: "Colombia", code: "CO" },
    { name: "Comoros", code: "KM" },
    { name: "Congo", code: "CG" },
    { name: "Congo, The Democratic Republic of the", code: "CD" },
    { name: "Cook Islands", code: "CK" },
    { name: "Costa Rica", code: "CR" },
    {
      name: 'Cote D"Ivoire',
      code: "CI",
    },
    { name: "Croatia", code: "HR" },
    { name: "Cuba", code: "CU" },
    { name: "Cyprus", code: "CY" },
    { name: "Czech Republic", code: "CZ" },
    { name: "Denmark", code: "DK" },
    { name: "Djibouti", code: "DJ" },
    { name: "Dominica", code: "DM" },
    { name: "Dominican Republic", code: "DO" },
    { name: "Ecuador", code: "EC" },
    { name: "Egypt", code: "EG" },
    { name: "El Salvador", code: "SV" },
    { name: "Equatorial Guinea", code: "GQ" },
    { name: "Eritrea", code: "ER" },
    { name: "Estonia", code: "EE" },
    { name: "Ethiopia", code: "ET" },
    { name: "Falkland Islands (Malvinas)", code: "FK" },
    { name: "Faroe Islands", code: "FO" },
    { name: "Fiji", code: "FJ" },
    { name: "Finland", code: "FI" },
    { name: "France", code: "FR" },
    { name: "French Guiana", code: "GF" },
    { name: "French Polynesia", code: "PF" },
    { name: "French Southern Territories", code: "TF" },
    { name: "Gabon", code: "GA" },
    { name: "Gambia", code: "GM" },
    { name: "Georgia", code: "GE" },
    { name: "Germany", code: "DE" },
    { name: "Ghana", code: "GH" },
    { name: "Gibraltar", code: "GI" },
    { name: "Greece", code: "GR" },
    { name: "Greenland", code: "GL" },
    { name: "Grenada", code: "GD" },
    { name: "Guadeloupe", code: "GP" },
    { name: "Guam", code: "GU" },
    { name: "Guatemala", code: "GT" },
    { name: "Guernsey", code: "GG" },
    { name: "Guinea", code: "GN" },
    { name: "Guinea-Bissau", code: "GW" },
    { name: "Guyana", code: "GY" },
    { name: "Haiti", code: "HT" },
    { name: "Heard Island and Mcdonald Islands", code: "HM" },
    { name: "Holy See (Vatican City State)", code: "VA" },
    { name: "Honduras", code: "HN" },
    { name: "Hong Kong", code: "HK" },
    { name: "Hungary", code: "HU" },
    { name: "Iceland", code: "IS" },
    { name: "India", code: "IN" },
    { name: "Indonesia", code: "ID" },
    { name: "Iran, Islamic Republic Of", code: "IR" },
    { name: "Iraq", code: "IQ" },
    { name: "Ireland", code: "IE" },
    { name: "Isle of Man", code: "IM" },
    { name: "Israel", code: "IL" },
    { name: "Italy", code: "IT" },
    { name: "Jamaica", code: "JM" },
    { name: "Japan", code: "JP" },
    { name: "Jersey", code: "JE" },
    { name: "Jordan", code: "JO" },
    { name: "Kazakhstan", code: "KZ" },
    { name: "Kenya", code: "KE" },
    { name: "Kiribati", code: "KI" },
    {
      name: 'Korea, Democratic People"s Republic of',
      code: "KP",
    },
    { name: "Korea, Republic of", code: "KR" },
    { name: "Kuwait", code: "KW" },
    { name: "Kyrgyzstan", code: "KG" },
    {
      name: 'Lao People"s Democratic Republic',
      code: "LA",
    },
    { name: "Latvia", code: "LV" },
    { name: "Lebanon", code: "LB" },
    { name: "Lesotho", code: "LS" },
    { name: "Liberia", code: "LR" },
    { name: "Libyan Arab Jamahiriya", code: "LY" },
    { name: "Liechtenstein", code: "LI" },
    { name: "Lithuania", code: "LT" },
    { name: "Luxembourg", code: "LU" },
    { name: "Macao", code: "MO" },
    { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
    { name: "Madagascar", code: "MG" },
    { name: "Malawi", code: "MW" },
    { name: "Malaysia", code: "MY" },
    { name: "Maldives", code: "MV" },
    { name: "Mali", code: "ML" },
    { name: "Malta", code: "MT" },
    { name: "Marshall Islands", code: "MH" },
    { name: "Martinique", code: "MQ" },
    { name: "Mauritania", code: "MR" },
    { name: "Mauritius", code: "MU" },
    { name: "Mayotte", code: "YT" },
    { name: "Mexico", code: "MX" },
    { name: "Micronesia, Federated States of", code: "FM" },
    { name: "Moldova, Republic of", code: "MD" },
    { name: "Monaco", code: "MC" },
    { name: "Mongolia", code: "MN" },
    { name: "Montenegro", code: "ME" },
    { name: "Montserrat", code: "MS" },
    { name: "Morocco", code: "MA" },
    { name: "Mozambique", code: "MZ" },
    { name: "Myanmar", code: "MM" },
    { name: "Namibia", code: "NA" },
    { name: "Nauru", code: "NR" },
    { name: "Nepal", code: "NP" },
    { name: "Netherlands", code: "NL" },
    { name: "Netherlands Antilles", code: "AN" },
    { name: "New Caledonia", code: "NC" },
    { name: "New Zealand", code: "NZ" },
    { name: "Nicaragua", code: "NI" },
    { name: "Niger", code: "NE" },
    { name: "Nigeria", code: "NG" },
    { name: "Niue", code: "NU" },
    { name: "Norfolk Island", code: "NF" },
    { name: "Northern Mariana Islands", code: "MP" },
    { name: "Norway", code: "NO" },
    { name: "Oman", code: "OM" },
    { name: "Pakistan", code: "PK" },
    { name: "Palau", code: "PW" },
    { name: "Palestinian Territory, Occupied", code: "PS" },
    { name: "Panama", code: "PA" },
    { name: "Papua New Guinea", code: "PG" },
    { name: "Paraguay", code: "PY" },
    { name: "Peru", code: "PE" },
    { name: "Philippines", code: "PH" },
    { name: "Pitcairn", code: "PN" },
    { name: "Poland", code: "PL" },
    { name: "Portugal", code: "PT" },
    { name: "Puerto Rico", code: "PR" },
    { name: "Qatar", code: "QA" },
    { name: "Reunion", code: "RE" },
    { name: "Romania", code: "RO" },
    { name: "Russian Federation", code: "RU" },
    { name: "RWANDA", code: "RW" },
    { name: "Saint Helena", code: "SH" },
    { name: "Saint Kitts and Nevis", code: "KN" },
    { name: "Saint Lucia", code: "LC" },
    { name: "Saint Pierre and Miquelon", code: "PM" },
    { name: "Saint Vincent and the Grenadines", code: "VC" },
    { name: "Samoa", code: "WS" },
    { name: "San Marino", code: "SM" },
    { name: "Sao Tome and Principe", code: "ST" },
    { name: "Saudi Arabia", code: "SA" },
    { name: "Senegal", code: "SN" },
    { name: "Serbia", code: "RS" },
    { name: "Seychelles", code: "SC" },
    { name: "Sierra Leone", code: "SL" },
    { name: "Singapore", code: "SG" },
    { name: "Slovakia", code: "SK" },
    { name: "Slovenia", code: "SI" },
    { name: "Solomon Islands", code: "SB" },
    { name: "Somalia", code: "SO" },
    { name: "South Africa", code: "ZA" },
    { name: "South Georgia and the South Sandwich Islands", code: "GS" },
    { name: "Spain", code: "ES" },
    { name: "Sri Lanka", code: "LK" },
    { name: "Sudan", code: "SD" },
    { name: "Suriname", code: "SR" },
    { name: "Svalbard and Jan Mayen", code: "SJ" },
    { name: "Swaziland", code: "SZ" },
    { name: "Sweden", code: "SE" },
    { name: "Switzerland", code: "CH" },
    { name: "Syrian Arab Republic", code: "SY" },
    { name: "Taiwan, Province of China", code: "TW" },
    { name: "Tajikistan", code: "TJ" },
    { name: "Tanzania, United Republic of", code: "TZ" },
    { name: "Thailand", code: "TH" },
    { name: "Timor-Leste", code: "TL" },
    { name: "Togo", code: "TG" },
    { name: "Tokelau", code: "TK" },
    { name: "Tonga", code: "TO" },
    { name: "Trinidad and Tobago", code: "TT" },
    { name: "Tunisia", code: "TN" },
    { name: "Turkey", code: "TR" },
    { name: "Turkmenistan", code: "TM" },
    { name: "Turks and Caicos Islands", code: "TC" },
    { name: "Tuvalu", code: "TV" },
    { name: "Uganda", code: "UG" },
    { name: "Ukraine", code: "UA" },
    { name: "United Arab Emirates", code: "AE" },
    { name: "United Kingdom", code: "GB" },
    { name: "United States", code: "US" },
    { name: "United States Minor Outlying Islands", code: "UM" },
    { name: "Uruguay", code: "UY" },
    { name: "Uzbekistan", code: "UZ" },
    { name: "Vanuatu", code: "VU" },
    { name: "Venezuela", code: "VE" },
    { name: "Viet Nam", code: "VN" },
    { name: "Virgin Islands, British", code: "VG" },
    { name: "Virgin Islands, U.S.", code: "VI" },
    { name: "Wallis and Futuna", code: "WF" },
    { name: "Western Sahara", code: "EH" },
    { name: "Yemen", code: "YE" },
    { name: "Zambia", code: "ZM" },
    { name: "Zimbabwe", code: "ZW" },
  ];
}

export const dataService = {
  getServiceCategories,
  getEventTypes,
  getStates,
  getCountries,
  getPriceUnits,
  search,
  useGetEventTypes,
};
