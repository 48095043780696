const eventWebsiteBaseUrl = process.env.EVENT_WEBSITE_BASE_URL;
export const constants = {
  UIF: "E4_UIF",
  VIF: "E4_VIF",
  EVENT: "E4_EV",
  LAUNCHED_DSH: "E4_DSH",
  DATA_EVENT_TYPES: "E4_EVENT_TYPES",
  DATA_SERVICE_TYPES: "E4_SERVICE_TYPES",
  //.
  eventWebsiteBaseUrl: eventWebsiteBaseUrl,
};
