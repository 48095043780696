import { constants } from "/helpers";
import {
  getCookie,
  getCookies,
  setCookie,
  hasCookie,
  deleteCookie,
} from "cookies-next";
import { fetchWrapper, urls } from "/helpers";
import useSWR from "swr";
import { signOut } from "next-auth/react";

import { useSession } from "next-auth/react";
import { swrResponse } from "/services";
import { setToStorage, getFromStorage } from "/helpers";

export const userService = {
  login,
  logout,
  getUser,
  setUser,
  getJWT,
  getVendor,
  setVendor,
};
export { useUpdateUser, useFetchUserProfile };

import Cookies from "js-cookie";

const useUpdateUser = (payload) => {
  const url = urls.userProfile + "/" + payload.profileId;

  return fetchWrapper.put(url, payload, { swr: false, authorize: true });
};

const useFetchUserProfile = (userId) => {
  const url = urls.userProfile + "/" + userId;
  const fetcher = fetchWrapper.get(url, { swr: true, authorize: true });
  return swrResponse(useSWR(url, fetcher));
};

async function login(token) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ token }),
  };

  const res = await fetch(urls.login, requestOptions);

  if (!res.ok) {
    const err = res.json();
    throw err.error;
  }

  return res.json();
}

function logout(callbackUrl) {
  // remove user from local storage, publish null to user subscribers and redirect to login page
  // localStorage.removeItem("user");
  // deleteCookie(constants.UIF);
  Cookies.remove(constants.UIF);
  Cookies.remove("E4_DSH");
  localStorage.removeItem("E4_DSH");
  localStorage.removeItem("E4_EV");
  localStorage.removeItem(constants.UIF);
  localStorage.removeItem("event");
  signOut({ callbackUrl: callbackUrl ?? "/" });
  // userSubject.next(null);
  // Router.push('/login');
}

function getUser() {
  // const cookie = getCookie(constants.UIF);
  const cookie = Cookies.get(constants.UIF);
  // console.log("cookie", cookie);
  if (!cookie) {
    console.log("CK not found");
    return null;
  }
  const user = JSON.parse(cookie.trim());
  // const user = JSON.parse(cookie.trim());
  // const user = getFromStorage(constants.UIF);

  return user;
}

async function setUser(user) {
  // console.log(user);
  let vendor;

  try {
    // if (user.vendor_profile) {
    //   let v = user.vendor_profile;
    //   vendor = {
    //     id: v.id,
    //     businessName: v.businessName,
    //     city: v.city,
    //     state: v.state,
    //     country: v.country,
    //     phone: v.phone,
    //     email: v.email,
    //   };
    //   delete user.vendor_profile;
    // }

    // // user = { ...vendor };

    // console.log(user);
    const value = JSON.stringify(user);

    Cookies.set(constants.UIF, value, {
      path: "/",
      sameSite: true,
      // httpOnly: true,
    });
  } catch (error) {
    console.log("set user error: " + error);
  }
}

function setVendor(vendor) {
  delete vendor.user;
  delete vendor.createdBy;
  delete vendor.updatedBy;
  delete vendor.galleries;
  delete vendor.credentials;
  delete vendor.services;

  vendor = {
    id: vendor.id,
    businessName: vendor.businessName,
    city: vendor.city,
    state: vendor.state,
    country: vendor.country,
    phone: vendor.phone,
    email: vendor.email,
  };

  const value = JSON.stringify(vendor);
  console.log(value);

  setCookie("constants.VIF", value, {
    path: "/",
    sameSite: true,
    // httpOnly: true,
  });
  // Cookies.set(constants.VIF, value, {
  //   path: "/",
  //   sameSite: true,
  //   // httpOnly: true,
  // });
  console.log(getVendor());
}

function getVendor() {
  const cookie = getCookie(constants.VIF);
  if (!cookie) {
    console.log("VendorCK not found");
    return null;
  }
  const vendor = JSON.parse(cookie.trim());

  return vendor;
}

function getJWT() {
  // const [cookies, setCookie] = useCookies([constants.UIF]);
  const cookie = getCookie(constants.UIF);
  if (!cookie) return null;
  const { jwt } = JSON.parse(cookie);
  return jwt;
}
