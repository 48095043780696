import { createContext, useState, useContext } from "react";
import { dataService } from "@/services/data-service";

const Context = createContext();

const Provider = ({ children, appData }) => {
  // console.log(appData);

  const [rsvpUrls, setRsvpUrls] = useState({});
  const [rsvpEid, setRsvpEid] = useState("");
  // console.log(props);
  const eventTypes = appData.eventTypes;
  const serviceTypes = appData.serviceTypes;

  const exposed = {
    rsvpUrls,
    setRsvpUrls,
    rsvpEid,
    setRsvpEid,
    eventTypes,
    serviceTypes,
  };

  return <Context.Provider value={exposed}>{children}</Context.Provider>;
};

export const useAppStates = () => useContext(Context);

export default Provider;
